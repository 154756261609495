<template>
  <div class="container project margin-top-40">
    <article>
      <header>
        <div class="row margin-bottom-40">
            <div class="col-sm">
                <h1 class="bitter-bold">
                    {{ activeProject.title }}
                </h1>
                <h2 class="bitter-bold">
                    {{ activeProject.client }}
                </h2>
                <h3 class="bitter-bold">
                    {{ activeProject.date }}
                </h3>
            </div>
        </div>
      </header>
      <div class="row">
          <section class="col-sm">
            <p v-for="(item, index) in activeProject.description" :key="index"
              v-html="item.text"></p>
          </section>
          <aside class="col-sm">
            <div class="row margin-bottom-20">
              <div class="col-sm">
                <div id="carouselExampleIndicators" class="carousel slide" data-interval="false">
                  <ol class="carousel-indicators">
                    <li v-for="(item, index) in activeProject.images" :key="index"
                      data-target="#carouselExampleIndicators"
                      :data-slide-to="index" :class="{'active': index === 0}"></li>
                  </ol>
                  <div class="carousel-inner">
                    <div v-for="(item, index) in activeProject.images"
                      :key="index" :class="{'carousel-item': true, 'active': index === 0}">
                      <img v-if="item.type === 'image'" class="d-block w-100 img-fluid
                        img-thumbnail"
                        :src="item.src" :width="item.width"
                        :height="item.height" :alt="item.alt_text"
                        role="presentation" />
                      <video v-else draggable="false" playsinline  autoplay loop
                        muted class="d-block w-100 img-fluid img-thumbnail">
                        <source type="video/mp4" :src="item.src">
                      </video>
                    </div>
                  </div>
                  <a class="carousel-control-prev" href="#carouselExampleIndicators"
                    role="button" data-slide="prev">
                    <i class="fa fa-chevron-left" aria-hidden="true"
                      style="text-shadow: 1px 1px 5px #000000; color: #ffffff;
                      font-size: 36px;"></i>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleIndicators"
                   role="button" data-slide="next">
                    <i class="fa fa-chevron-right" aria-hidden="true"
                      style="text-shadow: 1px 1px 5px #000000; color: #ffffff;
                      font-size: 36px;"></i>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <div class="card bg-light">
                  <div class="card-body" v-html="activeProject.image_caption"></div>
                </div>
              </div>
            </div>
          </aside>
      </div>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Project',
  props: {
    projects: Array,
    activeProject: Object,
  },
  mounted() {
    const routeId = parseInt(this.$route.params.id, 10);

    this.updateActiveProject(routeId);
  },
  methods: {
    updateActiveProject(routeId) {
      const selectedProject = this.projects.find((x) => x.id === routeId);
      let action;

      if (selectedProject !== undefined) {
        action = this.$store.commit('UPDATE_ACTIVE_PROJECT', selectedProject);
      } else {
        action = this.$router.push('/404');
      }

      return action;
    },
  },
  watch: {
    $route(to) {
      const routeId = parseInt(to.params.id, 10);

      this.updateActiveProject(routeId);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

  .carousel-indicators {
    li {
      background-color: rgba(0,0,0,0.75) !important;
      box-shadow: 1px 1px 3px #ffffff !important;
    }

    .active {
      background-color: #fff !important;
      box-shadow: 1px 1px 3px #000000 !important;
    }
  }
</style>
