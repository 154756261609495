<template>
  <div class="container project margin-top-40">
    <article>
      <header class="row margin-bottom-40">
        <div class="col-sm">
            <div>
                <h1 class="bitter-bold">
                    Resume
                </h1>
            </div>
        </div>
      </header>
      <div class="row">
        <div class="col-sm">
          <section v-for="(section_item, index) in resume.main" :key="index">
            <header>
              <h2 class="bitter-bold margin-bottom-40">
                {{ section_item.title }}
              </h2>
            </header>
            <div v-for="(content, content_index) in section_item.content" :key="content_index">
              <header v-if="content.title !== ''">
                <h3 class="bitter-bold">
                  {{ content.title }}
                </h3>
              </header>
              <div v-if="content.sub_title !== ''" class="font-18">
                <b>{{ content.sub_title }}</b>
              </div>
              <div v-if="content.date !== ''">
                {{ content.date }}
              </div>
              <div v-if="content.location !== ''" class="margin-bottom-20">
                {{ content.location }}
              </div>
              <div v-if="content.description.length > 0">
                <p v-for="(paragraph, text_index) in content.description" :key="text_index">
                  {{ paragraph.text }}
                </p>
              </div>
            </div>
          </section>
        </div>
        <aside class="col-sm">
          <div class="card">
            <div class="card-body">
              <div v-for="(aside_item, item_index) in resume.aside" :key="item_index">
                <h3 class="card-title h5">{{ aside_item.title }}</h3>
                <ul>
                  <li v-for="(content, content_index) in aside_item.content" :key="content_index">
                    {{ content.item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Resume',
  props: {
    resume: Object,
  },
};
</script>
